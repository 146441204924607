let formTheme = {};
let self = formTheme;

self.init = function(ctx){
    $(".checkbox-my-switch span, .checkbox-my-normal span", ctx).click(function () {
        $("input", $(this).parent()).trigger("click");
    });
    $(".form-group select", ctx).selectpicker({
        noneSelectedText: "",
        liveSearch: true,
        noneResultsText: "Aucun résultat",
        style: "btn-input"
    });
};

export default formTheme;
