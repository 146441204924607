require("bootstrap");
require('../css/site.scss');
require('../css/reset.css');
require('../css/styleadmin.css');
require('../bootstrap/css/bootstrap.css')
require("bootstrap-select");
import formTheme from "./modules/form-theme";
import { fancybox } from "../../node_modules/fancybox/dist/js/jquery.fancybox"; 
require('../../node_modules/fancybox/dist/css/jquery.fancybox.css');
import { validate } from "../../node_modules/jquery-validation/dist/jquery.validate"

function showLoader() {
    $("#loader").show();
}

$(function () {
    formTheme.init($(document));
    $(".inner-link").click(function () {
        location.href = $("a:first", this).attr("href");
    });
    ajaxModalMagasin();
    $(".modal .btn-submit").click(function () {
        let form = $(this).closest(".modal").find("form");
        showLoader();
        $.post({
            data: form.serialize(),
            url: form.attr("action"),
            complete: function (e) {
                location.href = location.href;
            }
        });
    });
    reworkAlert();
});

function reworkAlert() {
    let modal = $("#modal-rework");
    if (modal.length) {
        let saw = localStorage.getItem("sawReworkAlert");
        if (!saw) {
            modal.modal();
            localStorage.setItem("sawReworkAlert", "true");
        }
    }
}

function ajaxModalMagasin() {
    $(".nav-city").click(function () {
        showLoader();
        $.get({
            url: "/choix-magasin",
            success: function (e) {
                $("#loader").hide();
                let modal = $("#modal-body");
                $(".modal-body", modal).html($(e).find("form"));
                formTheme.init(modal);
                modal.modal();
            }
        })
    });
}